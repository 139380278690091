import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,

  rivalName: "casholot",
  casinoId: 108,
  GA_TRACKING:'G-G7T1B8ETRK',

  rivalChatGroupName: "Cash o' Lot",
  prettyName: "Cash o' Lot",
  contactEmail: "support@casholotcasino.com",
  docsEmail: "documents@casholotcasino.email",

  publicPromotions: [
    {id: 1, name: "400", state: "eligible", details: "welcome", imgPath: "assets/brands/casholot/promo.png"},
    {id: 2, name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/brands/casholot/promo.png"},
    {id: 3, name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/brands/casholot/promo.png"},
    {id: 4, name: "cashtravaganza", state: "eligible", details: "weekly", imgPath: "assets/brands/casholot/promo.png"},
    {id: 5, name: "comppoints", state: "eligible", details: "weekly", imgPath: "assets/brands/casholot/promo.png"}
  ],

  //SEO
  metaDescription: "Play Online Casino, open to USA players. Spin at Cash O' Lot Casino, bet on high quality slots, gamble with live table dealers, blackjack, and roulette. Win real cash, get no deposit bonus and chose secure mobile gaming with 24/7 support.",
  logoName: "casholot-online-casino-logo.png",
  logoAltText: "Cash O' Lot online casino logo with metallic script and gold spade coin icon popular for real money slots jackpots and online gambling bonuses."


};


